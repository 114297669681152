<template>
  <div class="userInfo">
    <div class="content">
      <div class="title">
        <h3>兰州市残疾人就业创业网络服务平台 | 账号安全</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="tab">
        <h1>2分钟急速创建简历，马上找到心仪工作</h1>
        <ul class="ul">
          <li class="active">
            <img src="~/static/login/01_active.png" alt="">
            <h3>基本信息</h3>
          </li>
          <li class="active">
            <img src="~/static/login/02_active.png" alt="">
            <h3>工作/实习经历</h3>
          </li>
          <li class="active">
            <img src="~/static/login/03_active.png" alt="">
            <h3>求职意向</h3>
          </li>
        </ul>
      </div>
      <el-form :model="form" :rules="error" label-width="120px" ref="form" class="form">
        <div class="form_title">
          <h2>求职意向</h2>
          <p>请填写您期望从事的工作</p>
        </div>
        <el-form-item label="期望职位" prop="jobTop">
          <ThreeLink
              codeType="job1"
              codeLevel="3"
              placeholder="请选择期望职位"
              class="w336 wrap_input"
              @setMValue="setScasValue($event,form,['jobTop','jobNext','jobPost'])"
              :defaultValue="[form.jobTop,form.jobNext,form.jobPost]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="期望行业" prop="hyTop">
          <ThreeLink
              codeType="hy1"
              codeLevel="3"
              placeholder="请选择所属行业"
              class="w336 wrap_input"
              @setMValue="setScasValue($event,form,['hyTop','hy'])"
              :defaultValue="[form.hyTop,form.hy]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="工作性质" prop="jobType">
          <el-radio-group v-model="form.jobType">
            <el-radio-button label="99">居家办公</el-radio-button>
            <el-radio-button label="100">全职</el-radio-button>
            <el-radio-button label="101">兼职</el-radio-button>
            <el-radio-button label="102">实习</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="期望地点" prop="provinceid">
          <ThreeLink
              codeType="provinceid"
              codeLevel="3"
              placeholder="请选择期望地点"
              class="w336 wrap_input"
              @setMValue="setScasValue($event,form,['provinceid','cityid','threeCityid'])"
              :defaultValue="[form.provinceid,form.cityid,form.threeCityid]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="期望薪资" prop="salary">
          <MulSelect
              :code="'user_salary'"
              class="w336 wrap_input"
              placeholder="请选择期望薪资"
              @setMValue="setSelectValue($event,'salary')"
              :defaultValue="form.salary"
          ></MulSelect>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="w180" plain @click="goRouter('/userLogin/userWork')">上一步</el-button>
          <el-button type="primary" class="w180 " style="margin-left: 20px" @click="handleSave('form')">立即提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
        :visible.sync="isShow"
        width="312px"
        :show-close="false"
        class="wrap_input"
        :before-close="handleClose">
      <div class="dialog_content">
        <img src="~/static/login/apply.png" alt="">
        <h3>恭喜您，简历创建成功</h3>
        <div class="btn flex">
          <el-button type="info" @click="goRouter('/')">残忍拒绝</el-button>
          <el-button type="primary" @click="goRouter('/job/')">去投递</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";

export default {
  name: "userApply",
  components: {
    MulSelect,
    ThreeLink
  },
  data() {
    return {
      form: {
        jobTop: '',
        jobNext: '',
        jobPost: '',
        hyTop: '',
        hy: '',
        salary:'',
      },
      error: {
        jobTop: [
          {required: true, message: "公司名称不能为空", trigger: "blur"},
        ],
        hyTop: [
          {required: true, message: "期望行业不能为空", trigger: "blur"},
        ],
        jobType: [
          {required: true, message: "工作性质不能为空", trigger: "change"},
        ],
        provinceid: [
          {required: true, message: "期望地点不能为空", trigger: "blur"},
        ],
        salary: [
          {required: true, message: "期望薪资不能为空", trigger: "change"},
        ],
      },
      isShow: false,
    }
  },
  methods: {
    // 提交表单
    handleSave(form) {
      this.$refs[form].validate(async (valid) => {
        this.form.endTime = this.endTime;
        try {
          if (valid) {
            let res = await this.$api.resumeExpect(this.form);
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.isShow = true;
            } else {
              this.isShow = false;
              this.$message.error(res.data.msg);
            }
          } else {
            this.isShow = false;
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    onTimes(val) {
      this.endTime = val;
    },
    onChange(val) {
      if (val) {
        this.disabled = true;
        this.form.endTime = new Date();
      } else {
        this.disabled = false;
      }
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        id: id,
      });
      window.location.href = href;
    },
    handleClose() {
      this.isShow = false;
    },
  },
  created() {

  }
}
</script>

<style scoped lang="less">
.userInfo {
  width: 100%;
  min-height: 100vh;
  background: white;

  .w336 {
    width: 336px;
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28A46D;
      }

      a {
        font-size: 18px;
        color: #66716C;
      }

      a:hover {
        color: #28A46D;
      }
    }

    .tab {
      width: 635px;
      margin: 0 auto;
      padding-top: 60px;

      h1 {
        font-size: 28px;
        padding-bottom: 36px;
        text-align: center;
      }

      .ul {
        display: flex;

        li {
          position: relative;
          flex: 1;
          text-align: center;

          h3 {
            font-size: 18px;
            margin-top: 6px;
            color: #66716c;
          }
        }

        li.active h3 {
          color: #28A46D;
        }

        li::after {
          content: '';
          position: absolute;
          top: 50%;
          left: -50px;
          transform: translateY(-50%);
          width: 102px;
          height: 2px;
          background: #CCD0CE;
        }

        li.active::after {
          background: #28A46D;
        }

        li:first-child::after {
          display: none;
        }
      }
    }

    .form {
      width: 792px;
      margin: 26px auto 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 36px 109px;

      .form_title {
        text-align: center;
        padding-bottom: 30px;

        h2 {
          font-size: 20px;
          margin-bottom: 6px;
        }

        p {
          font-size: 16px;
          color: #66716C;
        }
      }

      /deep/ .el-form-item {
        .el-textarea {
          .el-input__count {
            background: transparent;
          }
        }

        .el-input__inner::placeholder, .el-textarea__inner::placeholder {
          font-size: 14px;
          color: #999999;
        }

        .el-radio-group {
          .el-radio-button {
            width: 96px;
            height: 42px;
            margin-right: 18px;

            .el-radio-button__inner {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 16px;
              color: #66716C;
              border: none;
              border-radius: 6px;
              background: rgba(0, 0, 0, 0.05);
              padding: 0;
              width: 100%;
              height: 100%;

            }
          }

          .el-radio-button:last-child {
            margin-right: 0;
          }

          .is-active {
            .el-radio-button__inner {
              color: white;
              background: #28A46D;
            }
          }
        }

        .unit {
          font-size: 18px;
          color: #66716C;
          margin-left: 6px;
        }
      }
    }
  }

  .el-dialog {
    .dialog_content {
      text-align: center;

      h3 {
        font-size: 18px;
        margin-top: 24px;
      }

      p {
        color: #66716C;
        padding: 6px 0;
      }

      .btn {
        justify-content: center;
        margin-top: 10px;

        .el-button {
          width: 102px;
        }

        .el-button:nth-of-type(1) {
          margin-right: 20px;
        }
      }

      .el-button {
        margin-top: 16px;
        width: 132px;
      }

    }
  }
}
</style>
